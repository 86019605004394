export const staff = [
  {
    'name': 'Derp Herpenstein',
    'title': 'Back-End Developer',
    'url': '#derp-herpenstein',
    'image': '/images/avime/staff/derp-herpenstein.png',
  },
  {
    'name': 'rawdawg',
    'title': 'Project Lead',
    'url': 'https://twitter.com/Raw__Dawg',
    'image': '/images/avime/staff/rawdawg.png',
  },
  {
    'name': 'sweetbread',
    'title': 'Avime\'s Artist',
    'url': 'https://twitter.com/sweetbread_eth',
    'image': '/images/avime/staff/bread.png',
  },
  {
    'name': 'Now Hiring',
    'title': 'Community Manager',
    'url': '',
    'image': '/images/avime/staff/nowhiring.png',
  },
  {
    'name': 'accell',
    'title': 'Front-End Developer',
    'url': 'https://twitter.com/accellz0r',
    'image': '/images/avime/staff/accell.png',
  },
];
