export const blankTrait = {
  ID: -1,
  type: -1,
  tnum: -1,
  name: '',
  desc:  '',
  female: 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=',
  male: 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=',
  thumb: 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=',
  cardImg: 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=',
};
